import { ParsedUrlQuery } from 'node:querystring';

export function stringifyQuery(query: { [propName: string]: any }, initialSearchParams?: URLSearchParams) {
  const params = new URLSearchParams(initialSearchParams);

  Object.keys(query).forEach((key) => {
    const current = query[key];

    if (Array.isArray(current)) {
      current.forEach((value) => {
        if (value !== undefined) {
          params.append(key, String(value));
        }
      });
    } else if (current !== undefined) {
      params.set(key, current);
    }
  });

  params.sort();

  return params.toString();
}

export function joinQuery(url: string, query?: string) {
  return `${url}${query ? `/?${query}` : ''}`;
}

export const getString = (key: ParsedUrlQuery[string]): string | undefined => {
  if (Array.isArray(key)) {
    return key[0];
  }

  return key;
};

export const removeQuery = (path: string) => path.split('?')[0];
