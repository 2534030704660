import React, { useState } from 'react';
import { useRouter } from 'next/router';

import Email from 'assets/email/email-foreground-primary.svg';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import useForm from 'hooks/useForm';
import request, { matchError } from 'lib/request';
import { FormError, User } from 'types';

import { getString } from '../../../lib/query';
import * as Styled from './styles';

interface FormValues extends FormError {
  email: string;
}

const LoginForm = () => {
  const methods = useForm<FormValues>();
  const { formState, handleSubmit, register, control, setError } = methods;
  const { errors, isSubmitting } = formState;
  const router = useRouter();
  const [success, setSuccess] = useState(false);

  const onSubmit = handleSubmit(async (body) => {
    const response = await request<User>('users/send_magic_link', {
      body: { ...body, c: decodeURIComponent(getString(router.query.c)!) },
      method: 'patch',
    });

    if (matchError(response)) {
      response.mapFormErrors(control, setError);
      setSuccess(false);
    } else {
      setSuccess(true);
    }
  });

  return (
    <>
      {!success ? (
        <Styled.Form onSubmit={onSubmit}>
          <TextInput
            {...register('email', {
              required: true,
            })}
            label="Email"
            icon={Email}
            iconAlt="Email icon."
            placeholder="Enter your email address"
            error={errors.email}
            type="email"
            autoComplete="email"
            required
          />
          <Button
            data-testid="Login_submit"
            variant="secondary"
            size="big"
            type="submit"
            loading={isSubmitting}
            css={{ marginTop: '10px' }}
          >
            Send Magic Link
          </Button>
        </Styled.Form>
      ) : (
        <Styled.Success>Please check your email address.</Styled.Success>
      )}
    </>
  );
};

export default React.memo(LoginForm);
